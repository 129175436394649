import React from "react";
import { Heading, Tag, Paragraph } from "fiber-ui";
import { AiFillMail, AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { Voxelize } from "./components/Voxelize";
import "./App.css";

function App() {
  return (
    <div className="flex-col items-start justify-start flex gap-6 w-full max-w-[1000px] mx-auto">
      <header className="App-header">
        <Heading>Baltazar Zuniga Ruiz</Heading>
        <span
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            onClick={() => window.open("https://x.com/baltaaazr", "_blank")}
            style={{
              cursor: "pointer",
              display: "inline-flex",
              boxSizing: "border-box",
              alignItems: "center",
              height: "1.375rem",
              padding: "0.25rem 0.5rem",
              borderRadius: "0.25rem",
              backgroundColor: "#000000",
              color: "#fff",
              fontSize: "0.875rem",
              textAlign: "center",
              transform: "scale(1)",
              transformOrigin: "center",
              transition: "transform 100ms ease",
            }}
          >
            <FaXTwitter />
          </span>
          <span
            onClick={() => window.open("https://github.com/baltaazr", "_blank")}
            style={{
              cursor: "pointer",
              display: "inline-flex",
              boxSizing: "border-box",
              alignItems: "center",
              height: "1.375rem",
              padding: "0.25rem 0.5rem",
              borderRadius: "0.25rem",
              backgroundColor: "#2b3137",
              color: "#fff",
              fontSize: "0.875rem",
              textAlign: "center",
              transform: "scale(1)",
              transformOrigin: "center",
              transition: "transform 100ms ease",
            }}
          >
            <AiFillGithub />
          </span>
          <span
            onClick={() =>
              window.open("https://www.linkedin.com/in/baltaazr/", "_blank")
            }
            style={{
              cursor: "pointer",
              display: "inline-flex",
              boxSizing: "border-box",
              alignItems: "center",
              height: "1.375rem",
              padding: "0.25rem 0.5rem",
              borderRadius: "0.25rem",
              backgroundColor: "#0077b5",
              color: "#fff",
              fontSize: "0.875rem",
              textAlign: "center",
              transform: "scale(1)",
              transformOrigin: "center",
              transition: "transform 100ms ease",
            }}
          >
            <AiFillLinkedin />
          </span>
          <span
            onClick={() => {
              window.location.href = "mailto:zunigaruizb@gmail.com";
            }}
            style={{
              cursor: "pointer",
              display: "inline-flex",
              boxSizing: "border-box",
              alignItems: "center",
              height: "1.375rem",
              padding: "0.25rem 0.5rem",
              borderRadius: "0.25rem",
              backgroundColor: "#87d068",
              color: "#fff",
              fontSize: "0.875rem",
              textAlign: "center",
              transform: "scale(1)",
              transformOrigin: "center",
              transition: "transform 100ms ease",
            }}
          >
            <AiFillMail />
          </span>
        </span>
        <div className="mt-8 w-3/4 mx-auto">
          <Paragraph style={{ textAlign: "center" }}>
            Hello and welcome to my website! I am currently building{" "}
            <a
              href="https://cursor.sh"
              className="custom-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cursor
            </a>{" "}
            at{" "}
            <a
              href="https://anysphere.inc"
              className="custom-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Anysphere
            </a>{" "}
            to 1000000x software development!
            <br />
            <br />
            In my spare time, I enjoy working on personal projects. I am
            currently building Voxelize, an optimized, extensible, browser-based
            voxel game engine. Check it out below!
            <br />
          </Paragraph>
        </div>
        <Voxelize />
      </header>
    </div>
  );
}

export default App;
